
import { Component } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import BStates from '@/components/biz/b-states.vue'
import Emitter from '@/utils/emitter'

@Component({
	components: { BStates }
})
export default class extends Emitter {
	public $refs!: {
		form: ElForm
	}
	private inputMobilesStr = ''
	private isLoading = false
	private smsForm = {
		mobiles: [] as string[],
		content:
			'尊敬的客户，我是跨境物流格布小包的回访客服，我们即将联系您，为您提供咨询，请留意消息。'
	}
	private validateForm = {
		mobiles: [{ required: true, message: '请输入客户电话', trigger: 'blur' }]
	}

	async parseCode() {
		if (!this.inputMobilesStr.trim()) return ''
		const codes: any[] = Array.from(
			new Set(
				this.inputMobilesStr
					.trim()
					.split(/[;,\n]/)
					.map(item => {
						if (item.trim()) {
							const [code] = item.split(/[\t]{1,}/g)
							return code.trim()
						}
						return ''
					})
			)
		)
		this.smsForm.mobiles = codes
	}
	async sendMessage() {
		const isValidate = await this.$refs['form'].validate().catch(() => false)
		if (!isValidate) return
		if (this.isLoading) return
		this.isLoading = true
		const postData = {
			mobiles: this.smsForm.mobiles
		}
		const { data } = await this.$axios
			.post('/v1/jobline/agent/send-sms', postData)
			.catch(() => {
				return {
					data: null
				}
			})
			.finally(() => {
				this.isLoading = false
			})

		if (data) {
			this.$message.success('发送成功')
			this.resetForm()
		}
	}
	async resetForm() {
		this.smsForm['mobiles'] = []
	}
}
